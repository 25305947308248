// Plugins
import { eccaComponents } from "@ecca/ui-components";
import { VueQueryPlugin } from "@tanstack/vue-query";
import authentik from "@/plugins/authentik";
import pinia from "@/plugins/pinia";
import sentry, { registerSentryUser } from "@/plugins/sentry";
import { vueQueryPluginOptions } from "@/plugins/vueQuery";
import vuetify from "@/plugins/vuetify";
import { loadFonts } from "@/plugins/webfontloader";
import router from "@/router";
import type { App } from "vue";

export const registerPlugins = (app: App) => {
  app
    .use(sentry)
    .use(authentik, { registerSentryUser })
    .use(pinia)
    .use(router)
    .use(vuetify)
    .use(eccaComponents)
    .use(VueQueryPlugin, vueQueryPluginOptions);

  loadFonts();
};
