<script lang="ts" setup>
import { initMessaging } from "@/plugins/fcm";
import { useModelValueWrapper } from "@/shared/hooks/useModelValueWrapper";
import UiDialog from "@/shared/ui/UiDialog.vue";
import { useAppStore } from "@/store/app";

const props = defineProps<{
  dialogVisible: boolean;
}>();

const emit = defineEmits(["update:dialogVisible", "saveConfirmed"]);

const store = useAppStore();

const dialogVisibleMV = useModelValueWrapper(props, emit, "dialogVisible");

const allow = () => {
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      new Notification("Full power of the ECCA unlocked!");

      store.showNotificationDialog(false);
      initMessaging();
    }
  });
};
</script>

<template>
  <UiDialog
    v-model:dialogVisible="dialogVisibleMV"
    icon="$TriangleExclamation"
    iconColor="error"
    :title="'Allow browser notifications'"
    subtitle="Please allow browser notifications in order to use the full power of the ECCA application"
  >
    <VContainer
      v-if="!(store.getNotificationPermision === 'denied')"
      :fluid="true"
    >
      <VRow class="align-center text-center justify-center">
        <VCol cols="6">
          <EccaBtn block @click="allow"> Allow </EccaBtn>
        </VCol>
      </VRow>
    </VContainer>
  </UiDialog>
</template>
