<script setup lang="ts">
import { computed, nextTick, ref } from "vue";
import router from "@/router";

const info = computed(() => {
  if (window.location.href.includes("//localhost:")) return "LOCAL";
  if (window.location.href.includes("pr.ecca.cloud")) return "PR";
  if (window.location.href.includes("dev.ecca.cloud")) return "DEV";
  return "";
});

const setTitle = async () => {
  await nextTick();

  let prNumberTitle = "";
  if (info.value === "PR") {
    const match = window.location.href.match(/ui-(\d+)\.pr\.ecca\.cloud/);
    if (match) {
      prNumberTitle = " " + match[1];
    }
  }

  const titlePrefix = info.value ? `[${info.value}${prNumberTitle}] ` : "";

  if (!document.title.includes(titlePrefix)) {
    document.title = `${titlePrefix} ${document.title}`;
  }
};

const debugbar = ref();
const unicornsPerClick = 25;
const animationDuration = 2000;

const createMultipleUnicorns = () => {
  for (let i = 0; i < unicornsPerClick; i++) {
    const delay = Math.random() * 500;
    setTimeout(createUnicorn, delay);
  }
};

const createUnicorn = () => {
  const unicorn = document.createElement("div");
  unicorn.classList.add("unicorn");
  unicorn.textContent = "🦄";

  const randomSize = Math.random() * 0.8 + 0.5;
  unicorn.style.fontSize = `${randomSize}rem`;

  const windowWidth = window.innerWidth;
  const randomX = Math.random() * windowWidth;
  unicorn.style.left = `${randomX}px`;
  unicorn.style.bottom = `0px`;

  const randomHorizontalMovement = (Math.random() - 0.5) * 100;
  unicorn.style.setProperty(
    "--randomHorizontalMovement",
    `${randomHorizontalMovement}px`,
  );

  unicorn.style.filter = `hue-rotate(${Math.random() * 360}deg)`;
  debugbar.value.appendChild(unicorn);
  unicorn.style.animation = `floatUp ${animationDuration / 1000}s ease-in-out forwards`;

  setTimeout(() => {
    unicorn.remove();
  }, animationDuration);
};

router.afterEach(() => {
  setTitle();
});
</script>

<template>
  <div
    v-if="info !== ''"
    ref="debugbar"
    class="debug-info"
    :class="`debug-info--${info.toLowerCase()}`"
    @click="createMultipleUnicorns"
  >
    {{ info }}
  </div>
</template>

<style lang="scss">
.debug-info {
  position: fixed;
  bottom: 0;
  left: 0;
  color: white;
  font-size: 10px;
  padding: 0.5rem 6rem;
  z-index: 9999;
  border-top-right-radius: 10px;
  font-weight: bold;

  &--local {
    background-color: #8e0000;
  }

  &--dev {
    background-color: #5a21af;
  }

  &--pr {
    background-color: #a8a61c;
  }

  .unicorn {
    position: absolute;
    font-size: 2rem;
  }

  @keyframes floatUp {
    0% {
      transform: translateY(0) translateX(0) scale(1);
    }

    100% {
      transform: translateY(-100vh) translateX(var(--randomHorizontalMovement))
        scale(2);
      opacity: 0;
    }
  }
}
</style>
