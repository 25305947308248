<script lang="ts" setup>
import { IdType } from "@/modules/types";
import UiDialog from "@/shared/ui/UiDialog.vue";

const dialogVisibleMV = defineModel("dialogVisible");

defineProps<{
  dialogVisible?: any;
  currentWrittenContent: IdType;
}>();

const emit = defineEmits([
  "update:dialogVisible",
  "aiWrittenContentCancelProcess",
]);

const onClick = () => {
  dialogVisibleMV.value = false;
  emit("aiWrittenContentCancelProcess");
};
</script>
<template>
  <UiDialog
    v-model:dialogVisible="dialogVisibleMV"
    title="Are you sure you want to cancel?"
  >
    <EccaWrapper>
      <p class="text-center generate-ai-wc__info">
        Content is being generated, if you cancel, progress will not be saved.
      </p>

      <VContainer>
        <VRow>
          <VCol class="d-flex justify-center">
            <EccaBtn
              variant="outlined"
              class="mr-3"
              @click="dialogVisibleMV = false"
            >
              Back
            </EccaBtn>

            <EccaBtn @click="onClick"> Cancel </EccaBtn>
          </VCol>
        </VRow>
      </VContainer>
    </EccaWrapper>
  </UiDialog>
</template>

<style scoped>
.generate-ai-wc__info ul {
  display: inline-block;
}
</style>
