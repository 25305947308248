const THEME_GREEN: string = "greenTheme";
const THEME_BLUE: string = "blueTheme";
const THEME_ORANGE: string = "orangeTheme";
const THEME_LILAC: string = "lilacTheme";
const THEME_CYAN: string = "cyanTheme";
const THEME_RED: string = "redTheme";
const THEME_TEAL: string = "tealTheme";

const THEMES = [
  THEME_GREEN,
  THEME_BLUE,
  THEME_ORANGE,
  THEME_LILAC,
  THEME_CYAN,
  THEME_RED,
  THEME_TEAL,
];

export {
  THEMES,
  THEME_GREEN,
  THEME_BLUE,
  THEME_ORANGE,
  THEME_LILAC,
  THEME_CYAN,
  THEME_RED,
  THEME_TEAL,
};
