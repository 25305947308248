import { writtenContentRoutes } from "@/modules/writtenContent/routes";

export const productsRouteBasePath = "/products";

export const productRoutesNames = {
  root: "products",
  details: () => `${productRoutesNames.root}.details`,
  writtenContentTab: () => `${productRoutesNames.root}.written-content`,
  richContentTab: () => `${productRoutesNames.root}.rich-content`,
};

export const productsRoutes: any = [
  {
    path: productsRouteBasePath,
    component: () => import("@/modules/products/views/layouts/Default.vue"),
    meta: {
      auth: true,
      scope: ["products"],
    },
    children: [
      {
        path: productsRouteBasePath,
        name: productRoutesNames.root,
        component: () => import("@/modules/products/views/ProductsList.vue"),
      },
      {
        path: `${productsRouteBasePath}/:id`,
        name: productRoutesNames.details(),
        component: () => import("@/modules/products/views/ProductDetails.vue"),
      },
      {
        path: `${productsRouteBasePath}/:id/written-content`,
        name: productRoutesNames.writtenContentTab(),
        component: () => import("@/modules/products/views/ProductDetails.vue"),
        meta: {
          scope: ["contents:written_content"],
        },
      },
      {
        path: `${productsRouteBasePath}/:id/rich-content`,
        name: productRoutesNames.richContentTab(),
        component: () => import("@/modules/products/views/ProductDetails.vue"),
        meta: {
          scope: ["contents:rich"],
        },
      },
    ],
  },
  ...writtenContentRoutes(productsRouteBasePath),
];
