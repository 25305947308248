import { useMutation, useQuery } from "@tanstack/vue-query";
import { isCancel } from "axios";
import { MaybeRefOrGetter, toValue } from "vue";
import {
  deleteRequest,
  getBlobRequest,
  getRequest,
  patchRequest,
  postRequest,
  putBodyRequest,
} from "@/api/api";
import { useMutationOptions, useQueryOptions } from "@/api/tanstack";
import { IResponse, MutationOptions, QueryOptions } from "@/api/types";
import { config } from "@/data/config";
import { IdType, Retailer, WrittenContent } from "@/modules/types";

export const writtenContentKeys = {
  all: ["writtenContent"] as const,
  lists: () => [...writtenContentKeys.all, "list"] as const,
  list: (filters: any) => [...writtenContentKeys.lists(), { filters }] as const,
  details: () => [...writtenContentKeys.all, "detail"] as const,
  detail: (id: MaybeRefOrGetter<IdType>) =>
    [...writtenContentKeys.details(), id] as const,
  mutates: () => [...writtenContentKeys.all, "mutate"] as const,
  update: (id: MaybeRefOrGetter<IdType>) =>
    [...writtenContentKeys.mutates(), "update", toValue(id)] as const,
  createForProduct: (productId: IdType) =>
    [...writtenContentKeys.mutates(), "createForProduct", productId] as const,
  retailersLists: () => [...writtenContentKeys.all, "retailersLists"] as const,
  retailersList: (filters: any) =>
    [...writtenContentKeys.retailersLists(), { filters }] as const,
  delete: (id: MaybeRefOrGetter<IdType>) =>
    [...writtenContentKeys.mutates(), "delete", id] as const,
  export: () => [...writtenContentKeys.all, "export"] as const,
  aiGenerate: (writtenContentId: IdType) =>
    [...writtenContentKeys.all, "ai-generate", writtenContentId] as const,
};

export const queryGetWrittenContentListByProductId = (
  productId: MaybeRefOrGetter<string>,
  options: QueryOptions = {},
) =>
  useQuery({
    queryKey: writtenContentKeys.list({ productId: toValue(productId) }),
    queryFn: (): Promise<IResponse<WrittenContent[]>> =>
      getRequest(
        `${config.eccaApiUrl}v1/products/${toValue(productId)}/written-contents`,
      ),
    select: ({ data }) => data,
    ...useQueryOptions(options),
  });

export const queryGetWrittenContentAdaptationsListByParentId = (
  parentId: MaybeRefOrGetter<string>,
  options: QueryOptions = {},
) =>
  useQuery({
    queryKey: writtenContentKeys.list({ parentId: toValue(parentId) }),
    queryFn: (): Promise<IResponse<WrittenContent>> =>
      getRequest(
        `${config.eccaApiUrl}v1/written-contents/${toValue(parentId)}`,
      ),
    select: ({ data }) => data.adaptations,
    ...useQueryOptions(options),
  });

export const queryGetWrittenContentDetailsById = (
  writtenContentId: MaybeRefOrGetter<IdType>,
  options: QueryOptions = {},
) =>
  useQuery({
    queryKey: writtenContentKeys.detail(writtenContentId),
    queryFn: (): Promise<IResponse<WrittenContent>> =>
      getRequest(
        `${config.eccaApiUrl}v1/written-contents/${toValue(writtenContentId)}`,
      ),
    select: ({ data }) => data,
    ...useQueryOptions(options),
  });

export const queryUpdateWrittenContent = (
  writtenContentId: MaybeRefOrGetter<IdType>,
  data: MaybeRefOrGetter<Partial<WrittenContent>>,
  options: MutationOptions = {},
) =>
  useMutation({
    mutationFn: () =>
      putBodyRequest(
        `${config.eccaApiUrl}v1/written-contents/${toValue(writtenContentId)}`,
        toValue(data),
      ),
    mutationKey: writtenContentKeys.update(writtenContentId),
    ...useMutationOptions(options),
  });

export const queryCreateWrittenContentForProduct = (
  productId: MaybeRefOrGetter<IdType>,
  data: MaybeRefOrGetter<Partial<WrittenContent>>,
  options: MutationOptions = {},
) =>
  useMutation({
    mutationFn: () =>
      postRequest(
        `${config.eccaApiUrl}v1/products/${toValue(productId)}/written-contents`,
        toValue(data),
      ),
    mutationKey: writtenContentKeys.createForProduct(toValue(productId)),
    ...useMutationOptions(options),
  });

export const queryDeleteWrittenContent = (
  writtenContentId: MaybeRefOrGetter<IdType>,
  options: MutationOptions = {},
) =>
  useMutation({
    mutationFn: () =>
      deleteRequest(
        `${config.eccaApiUrl}v1/written-contents/${toValue(writtenContentId)}`,
        {},
      ),
    mutationKey: writtenContentKeys.delete(writtenContentId),
    ...useMutationOptions(options),
  });

export const queryPostToGetAvailableRetailersForWrittenContentByProductId = (
  data: MaybeRefOrGetter<Partial<WrittenContent>>,
  options: MutationOptions = {},
) =>
  useMutation({
    mutationKey: writtenContentKeys.retailersLists(),
    mutationFn: (): Promise<IResponse<Retailer[]>> =>
      postRequest(
        `${config.eccaApiUrl}v1/written-contents/free-retailers`,
        toValue(data),
      ),
    ...useMutationOptions(options),
  });

export const queryUpdateWrittenContentConfigFields = (
  writtenContentId: MaybeRefOrGetter<IdType>,
  options: MutationOptions = {},
) =>
  useMutation({
    mutationKey: writtenContentKeys.update(toValue(writtenContentId)),
    mutationFn: (): Promise<IResponse<WrittenContent>> =>
      patchRequest(
        `${config.eccaApiUrl}v1/written-contents/${toValue(writtenContentId)}/update-fields-config`,
      ),
    ...useMutationOptions(options),
  });

export const queryExportWrittenContent = (
  data: MaybeRefOrGetter,
  options: QueryOptions = {},
) =>
  useQuery({
    queryKey: writtenContentKeys.export(),
    queryFn: (): Promise<any> =>
      getBlobRequest(`${config.eccaApiUrl}v1/written-contents/export`, data),
    ...useQueryOptions(options),
  });

export const queryGenerateWrittenContentWithAI = (
  data: MaybeRefOrGetter,
  writtenContentID: IdType,
  options: MutationOptions = {},
) => {
  const mutation = useMutation({
    mutationKey: writtenContentKeys.aiGenerate(writtenContentID),
    mutationFn: (): Promise<any> =>
      postRequest(`${config.eccaApiUrl}v1/skald/queue`, toValue(data), {
        errorResolverType: "silent",
      }),
    ...useMutationOptions(options),
  });

  return {
    ...mutation,
    isCanceled: isCancel,
  };
};
