import authentikPlugin, { authentik } from "@ecca/vue-auth-plugin";
import { App } from "vue";

export default {
  install: (
    app: App,
    options: {
      registerSentryUser?: (email: string) => void;
    },
  ) => {
    const authentikIssuer = import.meta.env.VITE_AUTHENTIK_ISSUER;
    const authentikClientId = import.meta.env.VITE_AUTHENTIK_CLIENT_ID;

    app.use(authentikPlugin, {
      clientID: authentikClientId,
      issuer: authentikIssuer,
    });

    if (options.registerSentryUser) {
      options.registerSentryUser(authentik?.userInfo?.email as string);
    }
  },
};
