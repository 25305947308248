import { createApp } from "vue";
import Ecca from "@/Ecca.vue";
import { registerPlugins } from "@/plugins";
import "@ecca/ui-components/dist/style.css";
import { blurSave } from "@/shared/directives/blurSave";

const app = createApp(Ecca);
registerPlugins(app);

app.config.globalProperties.window = window;

app.mount("#ecca");

app.directive("blur-save", blurSave);
