// Utilities
import { createPinia } from "pinia";
import { App } from "vue";

export default {
  install: (
    app: App,
    options?: {
      createSentryPiniaPlugin?: () => any;
    },
  ) => {
    const pinia = createPinia();
    app.use(pinia);

    if (options?.createSentryPiniaPlugin) {
      pinia.use(options.createSentryPiniaPlugin());
    }
  },
};
